<template>
  <a-row>
    <a-col span="24">
      <a-collapse v-model:activeKey="activeKey">
        <template #expandIcon="{ isActive }">
          <RightOutlined :rotate="isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          showArrow
          key="5"
          header="Informações do Consulado"
          :class="$style.customStyle"
        >
          <a-row>
            <a-col span="24" :class="$style.space">
              <ConsulSelect
                reference="consul"
                name="Selecione o consulado de preferência"
                :isRequired="true"
              />
            </a-col>
          </a-row>
        </a-collapse-panel>
        <a-collapse-panel
          showArrow
          key="1"
          header="Informações Pessoais"
          :class="$style.customStyle"
        >
          <a-row>
            <a-col :span="isDesktop ? 8 : 12" :class="$style.space">
              <a-form-item
                ref="personal1GivenName"
                label="Nome"
                name="personal1GivenName"
                required
              >
                <a-input
                  v-model:value="vForm.personal1GivenName"
                  :readonly="readOnlyForm"
                >
                  <template #suffix>
                    <a-tooltip :title="PASSPORT_WITHOUT_NAME">
                      <info-circle-outlined
                        style="color: rgba(0, 0, 0, 0.45)"
                      />
                    </a-tooltip>
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item
                ref="personal1Doc"
                label="CPF"
                name="personal1Doc"
                required
              >
                <a-input
                  v-model:value="vForm.personal1Doc"
                  :readonly="readOnlyForm"
                >
                  <template #suffix> </template>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="isDesktop ? 9 : 12" :class="$style.space">
              <a-form-item
                ref="personal1Surname"
                label="Sobrenome"
                name="personal1Surname"
                required
              >
                <a-input
                  v-model:value="vForm.personal1Surname"
                  :readonly="readOnlyForm"
                >
                  <template #suffix>
                    <a-tooltip :title="SURNAMES_LIKE_PASSPORT">
                      <info-circle-outlined
                        style="color: rgba(0, 0, 0, 0.45)"
                      />
                    </a-tooltip>
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item
                ref="personal1Sex"
                label="Sexo"
                name="personal1Sex"
                required
              >
                <a-radio-group
                  name="personal1Sex"
                  v-model:value="vForm.personal1Sex"
                  :readonly="readOnlyForm"
                >
                  <a-radio-button value="M">Masculino</a-radio-button>
                  <a-radio-button value="F">Feminino</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="isDesktop ? 7 : 24" :class="$style.space"> </a-col>
            <a-col span="24">
              <a-form-item
                ref="personal1HasUsedOtherName"
                label="Você já usou outros nomes (ou seja, solteira, religioso, profissional, pseudônimo, etc.)?"
                required
                name="personal1HasUsedOtherName"
              >
                <a-radio-group
                  v-model:value="vForm.personal1HasUsedOtherName"
                  :readonly="readOnlyForm"
                >
                  <a-radio-button value="Y">Sim</a-radio-button>
                  <a-radio-button value="N">Não</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col span="24">
              <div v-if="vForm.personal1HasUsedOtherName == 'Y'">
                <a-col
                  span="24"
                  :class="[$style.space, $style.addMore, $style.outSpace]"
                  v-for="(item, amount) in vForm.personal1OtherSurname"
                  :key="amount"
                >
                  <a-row>
                    <a-col span="12" :class="[$style.space]">
                      <a-form-item
                        ref="personal1OtherGivenName"
                        label="Nome"
                        :name="['personal1OtherGivenName', amount, 'value']"
                        :rules="{
                          required: vForm.personal1HasUsedOtherName == 'Y',
                          message: 'Campo obrigatório',
                          trigger: 'blur',
                        }"
                      >
                        <a-input
                          v-model:value="
                            vForm.personal1OtherGivenName[amount].value
                          "
                          :readonly="readOnlyForm"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col span="12" :class="[$style.space]">
                      <a-form-item
                        ref="personal1OtherSurname"
                        label="Sobrenome"
                        :name="['personal1OtherSurname', amount, 'value']"
                        :rules="{
                          required: vForm.personal1HasUsedOtherName == 'Y',
                          message: 'Campo obrigatório',
                          trigger: 'blur',
                        }"
                      >
                        <a-input
                          v-model:value="
                            vForm.personal1OtherSurname[amount].value
                          "
                          :readonly="readOnlyForm"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-button
                    type="primary"
                    size="small"
                    @click="addOtherName()"
                    :disabled="canNotAddOtherName"
                    :class="$style.outSpace"
                  >
                    <template #icon>
                      <PlusOutlined />
                    </template>
                    Adicionar mais
                  </a-button>
                  <a-button
                    type="danger"
                    size="small"
                    @click="rmOtherName()"
                    :disabled="canNotRemoveOtherName"
                    :class="$style.outSpace"
                  >
                    <template #icon>
                      <MinusOutlined />
                    </template>
                    Remover
                  </a-button>
                </a-col>
              </div>
            </a-col>

            <a-col span="24" :class="$style.space">
              <a-form-item
                ref="personal1Marital"
                label="Estado Civil"
                name="personal1Marital"
                required
              >
                <a-select
                  v-model:value="vForm.personal1Marital"
                  :readonly="readOnlyForm"
                  placeholder="Seleciona estado Civil"
                >
                  <a-select-option value="M">Casado</a-select-option>
                  <a-select-option value="C">União Estavel</a-select-option>
                  <a-select-option value="P"
                    >União Civil Parceira Doméstica</a-select-option
                  >
                  <a-select-option value="S">Solteiro</a-select-option>
                  <a-select-option value="W">Viúvo(a)</a-select-option>
                  <a-select-option value="D">Divorciado</a-select-option>
                  <a-select-option value="L"
                    >Legalmente Separado</a-select-option
                  >
                  <a-select-option value="O">Outro</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="24">
              <div v-if="vForm.personal1Marital == 'O'">
                <a-col span="24" :class="[$style.space, $style.addMore]">
                  <a-form-item
                    ref="personal1MaritalExplanation"
                    label="Explicação"
                    name="personal1MaritalExplanation"
                    :required="vForm.personal1Marital == 'O'"
                  >
                    <a-textarea
                      v-model:value="vForm.personal1MaritalExplanation"
                      :readonly="readOnlyForm"
                      placeholder="Explique"
                      :rows="4"
                    />
                  </a-form-item>
                </a-col>
              </div>
            </a-col>
            <a-col span="24" :class="$style.space">
              <a-form-item
                ref="personal1Birth"
                label="Data de Nascimento"
                :rules="{
                  required: true,
                  trigger: 'blur',
                  validator: (rule, value) =>
                    validateDateEarlyThen(rule, value, 100),
                }"
                name="personal1Birth"
              >
                <a-date-picker
                  v-model:value="vForm.personal1Birth"
                  type="date"
                  placeholder="Selecione"
                  style="width: 100%"
                  @change="handleBirthDate"
                  :disabled-date="aboveCurrentDate"
                  :format="DEFAULT_DATE_FORMAT"
                  :readonly="readOnlyForm"
                />
              </a-form-item>
            </a-col>
            <a-col span="8" :class="$style.space">
              <a-form-item
                ref="personal1BirthCity"
                label="Cidade"
                name="personal1BirthCity"
                required
              >
                <a-input
                  name="personal1BirthCity"
                  v-model:value="vForm.personal1BirthCity"
                  :readonly="readOnlyForm"
                />
              </a-form-item>
            </a-col>
            <a-col span="8" :class="$style.space">
              <a-form-item
                ref="personal1BirthState"
                label="Estado"
                required
                name="personal1BirthState"
              >
                <a-input
                  name="personal1BirthState"
                  v-model:value="vForm.personal1BirthState"
                  :readonly="readOnlyForm"
                />
              </a-form-item>
            </a-col>
            <a-col span="8" :class="$style.space">
              <CountrySelect
                reference="personal1BirthCountry"
                name="País"
                :isRequired="true"
              />
            </a-col>
          </a-row>
        </a-collapse-panel>
        <a-collapse-panel
          showArrow
          key="2"
          header="Endereços e Contatos"
          :class="$style.customStyle"
        >
          <Address />
        </a-collapse-panel>
        <a-collapse-panel
          showArrow
          key="4"
          header="Trabalho e Educação"
          :class="$style.customStyle"
        >
          <WorkAndEducation />
        </a-collapse-panel>
        <a-collapse-panel
          showArrow
          key="3"
          header="Dados complementares"
          :class="$style.customStyle"
        >
          <a-row>
            <a-col span="24" :class="$style.space">
              <CountrySelect
                reference="personal2Nationality"
                name="Nacionalidade"
                :isRequired="true"
              />
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="isDesktop ? 12 : 24" :class="$style.space">
              <a-form-item
                ref="personal2HoldOtherNationality"
                label="Você tem ou já teve outra nacionalidade que não a indicada ao lado na nacionalidade?"
                name="personal2HoldOtherNationality"
                required
              >
                <a-radio-group
                  v-model:value="vForm.personal2HoldOtherNationality"
                  :readonly="readOnlyForm"
                >
                  <a-radio-button value="Y">Sim</a-radio-button>
                  <a-radio-button value="N">Não</a-radio-button>
                </a-radio-group>
              </a-form-item>
              <div v-if="vForm.personal2HoldOtherNationality == 'Y'">
                <a-col
                  span="24"
                  :class="[$style.space, $style.addMore]"
                  v-for="(
                    passport, amount
                  ) in vForm.personal2HasOtherNationalityPassport"
                  :key="amount"
                >
                  <MultiCountrySelect
                    reference="personal2OtherNationalityCountry"
                    :identification="amount"
                    name="País"
                    :isRequired="vForm.personal2HoldOtherNationality == 'Y'"
                  />
                  <a-form-item
                    ref="personal2HasOtherNationalityPassport"
                    label="Você possui passaporte para essa nacionalidade?"
                    :name="[
                      'personal2HasOtherNationalityPassport',
                      amount,
                      'value',
                    ]"
                    :rules="{
                      required: vForm.personal2HoldOtherNationality == 'Y',
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-radio-group
                      v-model:value="
                        vForm.personal2HasOtherNationalityPassport[amount].value
                      "
                      :readonly="readOnlyForm"
                    >
                      <a-radio-button value="Y">Sim</a-radio-button>
                      <a-radio-button value="N">Não</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                  <a-form-item
                    v-if="
                      vForm.personal2HasOtherNationalityPassport[amount]
                        .value == 'Y'
                    "
                    ref="personal2HasOtherNationalityPassportNumber"
                    label="Número do passaporte"
                    :name="[
                      'personal2HasOtherNationalityPassportNumber',
                      amount,
                      'value',
                    ]"
                    :rules="{
                      required:
                        vForm.personal2HasOtherNationalityPassport[amount]
                          .value == 'Y',
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-input
                      v-model:value="
                        vForm.personal2HasOtherNationalityPassportNumber[amount]
                          .value
                      "
                      :readonly="readOnlyForm"
                    />
                  </a-form-item>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-button
                    type="primary"
                    size="small"
                    @click="addNationality()"
                    :disabled="canNotAddNationality"
                    :class="$style.outSpace"
                  >
                    <template #icon>
                      <PlusOutlined />
                    </template>
                    Adicionar mais
                  </a-button>
                  <a-button
                    type="danger"
                    size="small"
                    @click="rmNationality()"
                    :disabled="canNotRemoveNationality"
                    :class="$style.outSpace"
                  >
                    <template #icon>
                      <MinusOutlined />
                    </template>
                    Remover
                  </a-button>
                </a-col>
              </div>
            </a-col>
            <a-col :span="isDesktop ? 12 : 24" :class="$style.space">
              <a-form-item
                ref="personal2IsPermanentResidentOtherCountry"
                label="Você é residente permanente de um país/região diferente do seu país/região de origem (nacionalidade) indicado acima?"
                name="personal2IsPermanentResidentOtherCountry"
                required
              >
                <a-radio-group
                  v-model:value="vForm.personal2IsPermanentResidentOtherCountry"
                  :readonly="readOnlyForm"
                >
                  <a-radio-button value="Y">Sim</a-radio-button>
                  <a-radio-button value="N">Não</a-radio-button>
                </a-radio-group>
              </a-form-item>
              <div v-if="vForm.personal2IsPermanentResidentOtherCountry == 'Y'">
                <a-col
                  span="24"
                  :class="[$style.space, $style.addMore]"
                  v-for="(
                    item, amount
                  ) in vForm.personal2PermanentResidentOtherCountry"
                  :key="amount"
                >
                  <MultiCountrySelect
                    reference="personal2PermanentResidentOtherCountry"
                    name="País"
                    :identification="amount"
                    :isRequired="
                      vForm.personal2IsPermanentResidentOtherCountry == 'Y'
                    "
                  />
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-button
                    type="primary"
                    :class="$style.outSpace"
                    size="small"
                    :disabled="canNotAddOtherNationality"
                    @click="addOtherNationality()"
                  >
                    <template #icon>
                      <PlusOutlined />
                    </template>
                    Adicionar mais
                  </a-button>
                  <a-button
                    type="danger"
                    size="small"
                    @click="rmOtherNationality()"
                    :disabled="canNotRemoveOtherNationality"
                    :class="$style.outSpace"
                  >
                    <template #icon>
                      <MinusOutlined />
                    </template>
                    Remover
                  </a-button>
                </a-col>
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="24" :class="$style.space">
              <a-form-item label="Quais idiomas você fala?">
                <a-checkbox-group v-model:value="vForm.languages">
                  <a-checkbox value="portugues">Português</a-checkbox>
                  <a-checkbox value="english">Inglês</a-checkbox>
                  <a-checkbox value="spanish">Espanhol</a-checkbox>
                  <a-checkbox value="german">Alemão</a-checkbox>
                  <a-checkbox value="italian">Italiano</a-checkbox>
                  <a-checkbox value="japanese">Japonês</a-checkbox>
                  <a-checkbox value="other">Outros</a-checkbox>
                </a-checkbox-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="24" :class="$style.space">
              <h4>
                Informações de Contatos de Emergência (obrigatório para visto de
                estudante F-1)
              </h4>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" :class="$style.space">
              <a-form-item
                label="Nome completo do Contato de Emergência 1"
                name="emergencyContact1Name"
              >
                <a-input
                  v-model:value="vForm.emergencyContact1Name"
                  placeholder="Nome completo"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12" :class="$style.space">
              <a-form-item
                label="Nome completo do Contato de Emergência 2"
                name="emergencyContact2Name"
              >
                <a-input
                  v-model:value="vForm.emergencyContact2Name"
                  placeholder="Nome completo"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" :class="$style.space">
              <a-form-item
                label="Endereço completo do Contato de Emergência 1"
                name="emergencyContact1Address"
              >
                <a-input
                  v-model:value="vForm.emergencyContact1Address"
                  placeholder="Endereço completo"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12" :class="$style.space">
              <a-form-item
                label="Endereço completo do Contato de Emergência 2"
                name="emergencyContact2Address"
              >
                <a-input
                  v-model:value="vForm.emergencyContact2Address"
                  placeholder="Endereço completo"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" :class="$style.space">
              <a-form-item
                label="Telefone com DDD do Contato de Emergência 1"
                name="emergencyContact1Phone"
              >
                <a-input
                  v-model:value="vForm.emergencyContact1Phone"
                  placeholder="Telefone com DDD"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12" :class="$style.space">
              <a-form-item
                label="Telefone com DDD do Contato de Emergência 2"
                name="emergencyContact2Phone"
              >
                <a-input
                  v-model:value="vForm.emergencyContact2Phone"
                  placeholder="Telefone com DDD"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" :class="$style.space">
              <a-form-item
                label="Email do Contato de Emergência 1"
                name="emergencyContact1Email"
              >
                <a-input
                  v-model:value="vForm.emergencyContact1Email"
                  placeholder="Email"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12" :class="$style.space">
              <a-form-item
                label="Email do Contato de Emergência 2"
                name="emergencyContact2Email"
              >
                <a-input
                  v-model:value="vForm.emergencyContact2Email"
                  placeholder="Email"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" :class="$style.space">
              <a-form-item
                label="Relação com o F1 do Contato de Emergência 1"
                name="emergencyContact1Relation"
              >
                <a-input
                  v-model:value="vForm.emergencyContact1Relation"
                  placeholder="Relação"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12" :class="$style.space">
              <a-form-item
                label="Relação com o F1 do Contato de Emergência 2"
                name="emergencyContact2Relation"
              >
                <a-input
                  v-model:value="vForm.emergencyContact2Relation"
                  placeholder="Relação"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-collapse-panel>
      </a-collapse>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent, ref } from "vue";
import {
  InfoCircleOutlined,
  RightOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons-vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import {
  SURNAMES_LIKE_PASSPORT,
  PASSPORT_WITHOUT_NAME,
} from "@/utils/tooltipConstants";
import { DEFAULT_DATE_FORMAT, DEFAULT_MAX_ADD_AMOUNT } from "@/utils/Constants";
import CountrySelect from "@/components/CountrySelect/CountrySelect";
import MultiCountrySelect from "@/components/CountrySelect/MultiCountrySelect";
import ConsulSelect from "@/components/ConsulSelect/ConsulSelect";
import { isCovidTimes } from "@/utils/isCovidTimes";
import Address from "./Address/Address";
import WorkAndEducation from "./WorkAndEducation/WorkAndEducation";
import { isDesktop } from "@/utils/isAgent";
import { validateDate, validateDateEarlyThen } from "@/utils/validation";
import moment from "moment";

export default defineComponent({
  components: {
    InfoCircleOutlined,
    RightOutlined,
    PlusOutlined,
    MinusOutlined,
    CountrySelect,
    MultiCountrySelect,
    Address,
    WorkAndEducation,
    ConsulSelect,
  },
  setup() {
    const activeKey = ref(["1", "2", "3", "4", "5"]);
    return {
      activeKey,
      validateDate,
      validateDateEarlyThen,
    };
  },
  data() {
    return {
      amountNationality: 1,
      amountOtherNationality: 1,
      SURNAMES_LIKE_PASSPORT,
      PASSPORT_WITHOUT_NAME,
      DEFAULT_DATE_FORMAT,
      isDesktop,
    };
  },
  computed: {
    ...mapState("application", ["vForm"]),
    ...mapGetters("application", ["readOnlyForm"]),
    inCovidTimes() {
      return isCovidTimes();
    },
    canNotAddNationality() {
      return !(
        this.vForm.personal2HasOtherNationalityPassport.length <
        DEFAULT_MAX_ADD_AMOUNT
      );
    },
    canNotAddOtherName() {
      return !(
        this.vForm.personal1OtherSurname.length < DEFAULT_MAX_ADD_AMOUNT
      );
    },
    canNotAddOtherNationality() {
      return !(
        this.vForm.personal2PermanentResidentOtherCountry.length <
        DEFAULT_MAX_ADD_AMOUNT
      );
    },
    canNotRemoveOtherName() {
      return !(this.vForm.personal1OtherSurname.length > 1);
    },
    canNotRemoveNationality() {
      return !(this.vForm.personal2HasOtherNationalityPassport.length > 1);
    },
    canNotRemoveOtherNationality() {
      return !(this.vForm.personal2PermanentResidentOtherCountry.length > 1);
    },
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    aboveCurrentDate(current) {
      return current && current > moment().endOf("day");
    },
    addNationality() {
      if (!this.canNotAddNationality) {
        this.vForm.personal2HasOtherNationalityPassport.push({ value: "" });
        this.vForm.personal2OtherNationalityCountry.push({ value: "" });
      }
    },
    addOtherName() {
      if (!this.canNotAddOtherName) {
        this.vForm.personal1OtherGivenName.push({ value: "" });
        this.vForm.personal1OtherSurname.push({ value: "" });
      }
    },
    rmNationality() {
      if (this.vForm.personal2HasOtherNationalityPassport.length > 1) {
        this.vForm.personal2HasOtherNationalityPassport.pop();
        this.vForm.personal2OtherNationalityCountry.pop();
      }
    },
    rmOtherName() {
      if (this.vForm.personal1OtherGivenName.length > 1) {
        this.vForm.personal1OtherSurname.pop();
        this.vForm.personal1OtherGivenName.pop();
      }
    },
    addOtherNationality() {
      if (!this.canNotAddOtherNationality) {
        this.vForm.personal2PermanentResidentOtherCountry.push({ value: "" });
      }
    },
    rmOtherNationality() {
      if (this.vForm.personal2PermanentResidentOtherCountry.length > 1) {
        this.vForm.personal2PermanentResidentOtherCountry.pop();
      }
    },
    handleBirthDate(val) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.personal1BirthDay = day;
      this.vForm.personal1BirthMonth = month;
      this.vForm.personal1BirthYear = year;
    },
  },
  mounted() {
    // const personal1Birth = moment(this.vForm.personal1Birth);
    // this.setSaveFilledForm({ ...this.vForm, personal1Birth });
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
