<template>
  <a-row>
    <a-col span="24">
      <a-collapse v-model:activeKey="activeKey">
        <template #expandIcon="{ isActive }">
          <RightOutlined :rotate="isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          showArrow
          key="2"
          header="Informações sobre companheiros de viagem"
          :class="$style.customStyle"
        >
          <Companions />
        </a-collapse-panel>
        <a-collapse-panel
          showArrow
          key="3"
          header="Viagens anteriores"
          :class="$style.customStyle"
        >
          <Previous />
        </a-collapse-panel>
        <a-collapse-panel
          showArrow
          key="4"
          header="Viagens anteriores ao Estados Unidos"
          :class="$style.customStyle"
        >
          <PreviousUsTravel />
        </a-collapse-panel>
      </a-collapse>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent, ref } from "vue";
import { mapMutations, mapState } from "vuex";
import {
  RightOutlined,
  // InfoCircleOutlined,
} from "@ant-design/icons-vue";
import Companions from "./Companions/Companions";
import Previous from "./Previous/Previous";
import PreviousUsTravel from "./Previous/PreviousUsTravel";
export default defineComponent({
  setup() {
    const activeKey = ref(["1", "2", "3", "4"]);
    return {
      activeKey,
    };
  },
  components: {
    RightOutlined,
    Companions,
    PreviousUsTravel,
    Previous,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("application", ["vForm"]),
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
